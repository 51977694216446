import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import Layout from "../components/shared/layout-en"
import SEO from "../components/seo"
import FindWork from "../components/homepage__findwork"
import Process from "../components/homepage__process"
import Review from "../components/homepage__review"
import Steps from "../components/homepage__steps"
import Benefits from "../components/homepage__benefit"
import Cta from "../components/homepage__cta"
import { graphql } from "gatsby"

export const query = graphql`
  {
    prismic {
      allJobs(lang: "en-ca") {
        edges {
          node {
            title
            salaire
            city
            province
            type_de_salaire
            image_de_l_emploi
            _meta {
              uid
              lang
              type
            }
          }
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  let jobs = data.prismic.allJobs.edges
  let pageDescription = "Recruitment as simple as drinking a cup of TEA. Centralize all your presonnel needs. Find the perfect solution to hiring needs."
  return (
    <>
      <Layout french={false}>
        <SEO title="Home" description={pageDescription} lang="en" />
        <FindWork french={false} jobs={jobs} />
        <Process french={false} />
        <Review french={false} />
        <Steps french={false} />
        <Benefits french={false} />
        <Cta french={false} />
      </Layout>
    </>
  )
}

export default IndexPage
